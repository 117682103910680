<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>我的考试</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column align="center" type="selection" min-width="5%">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="examName"
          label="考试名称"
          min-width="40%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="考试时间"
          min-width="50%"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            <span>{{
              scope.row.createTime.substring(
                0,
                scope.row.createTime.indexOf('.')
              )
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="score"
          label="考试成绩"
          min-width="40%"
          :show-overflow-tooltip="true"
          ><template slot-scope="scope">
            <div :style="{ color: scope.row.score ? 'red' : 'red' }">
              {{ scope.row.score }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: center; margin-top: 20px">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {jcExamResultPageInfo } from "@/api/exam/exam";
export default {
  name: "",
  data() {
    return {
      total: 1,
      tableData: [],
      multipleSelection: "",
      id:'',
      list: [], //考试数组

      params: {
        pageNo: 1,
        pageSize: 10
      },
    };
  },
  watch: {},
  props: {},
  components: {},

  created() {
    this.params.jcUserId = localStorage.getItem("userId");
    this.getnameList();
  },

  computed: {},
  mounted() {},
  methods: {

    //考试名称
    getnameList()  {
      this.params.jcUserId = localStorage.getItem("userId");
      jcExamResultPageInfo(this.params).then((res) => {
        this.tableData = res.data.rows;
        this.total = res.data.totalRows;
      });
    },

    // 翻页
    chengePagination(val) {
      this.params.pageNo = val;
      this.getnameList()
    },

    handleClick(tab, event) {
      console.log(tab, event)
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

::v-deep .el-tabs {
  margin-top: 10px;
  background-color: #fff;
  .el-tabs__nav-wrap {
    background-color: #fafafa;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        border: none;
      }
      .is-active {
        background-color: #fff;
      }
    }
  }
}

.viewDetails {
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
</style>
